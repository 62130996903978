<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <h3 class="mb-10 font-weight-bold text-dark">
                  Warehouse Inbound Transaction
                </h3>

                <b-form-group label-cols-sm="3" id="input-group-1" label="Reference Number" label-for="input-3">
                  <b-form-input
                    v-model="referenceNo"
                    id="input-3"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    name="referenceNo"
                  ></b-form-input>
                </b-form-group>

                <b-button size="md" variant="white" class="mb-2" @click="addTransaction">
                  <b-icon icon="plus-circle" aria-label="Add"></b-icon>
                </b-button>

                <div class="row align-items-baseline" v-for="(move, index) in movement" :key="index">
                  <div class="col-6">
                    <b-form-group label-cols-sm="3" id="input-group-1" label="Product" label-for="input-3">
                      <v-select
                        v-model="move.product"
                        label="name"
                        :options="products"
                        :reduce="product => product.id"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label-cols-sm="4" id="input-group-1" label="Quantity" label-for="input-3">
                      <b-form-input
                        v-model="move.qty"
                        id="input-3"
                        type="number"
                        class="form-control form-control-sm"
                        name="description"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-1">
                    <b-button size="sm" variant="danger" class="mb-2" @click="removeRow(index)">
                      <b-icon icon="trash" aria-label="Delete"></b-icon>
                    </b-button>
                  </div>
                  <div class="col-1">
                    <i class="flaticon2-check-mark text-success" v-if="move.status"></i>
                  </div>
                </div>

                <div class="d-flex justify-content-between border-top pt-10">
                  <button
                    class="btn-primary btn font-weight-bolder text-uppercase px-6"
                    :disabled="loading"
                    @click="submit"
                  >
                    <b-spinner small v-show="loading"></b-spinner>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllProduct, inbound } from "@/api/warehouse.api";
// import { useNotify } from "@/utils/notify";

import { ref, reactive, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "inboundWarehouse",
  components: { vSelect },
  setup() {
    // const { notify } = useNotify(context);

    const referenceNo = ref("");

    const loading = ref(false);
    const movement = reactive([]);

    const products = ref([]);
    onMounted(async () => {
      const response = await getAllProduct();
      products.value = response.data;
    });

    const addTransaction = function() {
      movement.push({ product: "", qty: 0, status: null });
    };

    const removeRow = function(index) {
      movement.splice(index, 1);
    };

    const submit = async function() {
      loading.value = true;
      movement.forEach(async move => {
        const payload = {
          reference_no: referenceNo.value,
          product: move.product,
          qty: move.qty
        };
        console.log(payload);
        try {
          await inbound(payload);
          move.status = true;
          // notify("Product created", `The product is created`);
          // context.root.$router.push({ name: "ListWarehouseProduct" });
        } catch (e) {
          console.error(e);
        }
      });

      loading.value = false;
    };

    return {
      referenceNo,
      loading,
      addTransaction,
      removeRow,
      products,
      movement,

      submit
    };
  }
};
</script>
